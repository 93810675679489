import { DimensionListWithCodes } from "./DimensionListWithCodes";

const facilityTypes: Record<string, string> = {
  "0": "Gate connector",
  "1": "Limited access highway",
  "2": "Principal arterial",
  "3": "Major arterial",
  "4": "Minor arterial",
  "5": "Major collector",
  "6": "Minor collector",
  "7": "Local street",
  "8": "Service road",
  "9": "Rural road",
  "11": "Freeway ramp",
  "12": "Principal ramp",
  "13": "Major ramp",
  "14": "Minor ramp",
  "15": "Local ramp",
  "21": "Busway",
  "31": "Pedestrian",
  "32": "Non-motorized",
  "81": "Ferry",
  "97": "Abandoned",
  "98": "Construction",
};

const areaTypes: Record<string, string> = {
  "1": "Dense Urban",
  "2": "Urban",
  "3": "Suburban Commercial",
  "4": "Suburban Residential",
  "5": "Rural",
};

const FHWAFunctionalClasses: Record<string, string> = {
  "1": "Interstate",
  "2": "Principal arterial - other freeways and expressways",
  "3": "Principal arterial - other",
  "4": "Minor arterial",
  "5": "Major collector",
  "6": "Minor collector",
  "7": "Local street",
};

export const roadShapeFileColumns = [
  {
    column: "factype",
    type: "Integer",
    description: (
      <div>
        Facility type for the road segment. Value for gate connectors: 0{" "}
        <DimensionListWithCodes elements={facilityTypes} />
      </div>
    ),
  },
  {
    column: "st_name",
    type: "Text",
    description: "Road name. NULL for gate connectors",
  },
  {
    column: "to_node",
    type: "Integer64",
    description:
      "Original TO node ID (nationwide unique). For gate connectors: 0 if the gate is at the TO end of the line",
  },
  {
    column: "from_node",
    type: "Integer64",
    description:
      "Original FROM node ID (nationwide unique). For gate connectors: 0 if the gate is at the FROM end of the line",
  },
  {
    column: "segment_id",
    type: "Text",
    description:
      "Textual segment ID (e.g. 131567937_2_NW_18) NULL for gate connectors, nationwide unique for other segments",
  },
  {
    column: "A",
    type: "Integer",
    description: "Renumbered FROM node ID, mapped into 32,001-999,999 range",
  },
  {
    column: "B",
    type: "Integer",
    description: "Renumbered TO node ID, mapped into 32,001-999,999 range",
  },
  {
    column: "distance",
    type: "Integer",
    description: "Length of the segment (miles), rounded to 3 or 4 decimal places. Value for gate connector: 0",
  },
  {
    column: "highway",
    type: "Text",
    description: (
      <div>
        OpenStreetMap highway road type attributes which are the basis for defining facility types. Values are
        documented on the OpenStreetMap OSM wiki page (
        <a href="https://wiki.openstreetmap.org/wiki/Key:highway" target="_blank" rel="noreferrer">
          https://wiki.openstreetmap.org/wiki/Key:highway
        </a>
        ) Value for gate connectors: "highway"
      </div>
    ),
  },
  {
    column: "atype",
    type: "Integer",
    description: (
      <div>
        Area type attribute based on block group socioeconomic data (1,2,3,4,5). Value for gate connectors: 0{" "}
        <DimensionListWithCodes elements={areaTypes} />
      </div>
    ),
  },
  {
    column: "spd_limit",
    type: "Integer",
    description: "Estimated speed limit based on OpenStreetMap MAXSPEED classification, -1 for unknown",
  },
  {
    column: "oneway",
    type: "Integer",
    description: "Flag for one-way roadways (0 = no, 1 = yes)",
  },
  {
    column: "ramp",
    type: "Integer",
    description: "Flag for roadways on bridge structures (0 = no, 1 = yes)",
  },
  {
    column: "bridge",
    type: "Integer",
    description: "Flag for roadways on bridge structures (0 = no, 1 = yes)",
  },
  {
    column: "tunnel",
    type: "Integer",
    description: "Flag for roadways in tunnels (0 = no, 1 = yes)",
  },
  {
    column: "divider",
    type: "Integer",
    description: "Flag for physical divider in the median of a roadway (0 = no, 1 = yes)",
  },
  {
    column: "lanes",
    type: "Integer",
    description: "Number of lanes",
  },
  {
    column: "cap_lane",
    type: "Integer",
    description: "Estimated per-lane capacity",
  },
  {
    column: "urbanrural",
    type: "Integer",
    description:
      "Urban/rural values are set using census 2020 urban area boundaries based on population density, without consideration of employment. (0 = rural, 1= urban)",
  },
  {
    column: "fhwa_class",
    type: "Integer",
    description: (
      <div>
        FHWA functional class <sup>2</sup>
        <DimensionListWithCodes elements={FHWAFunctionalClasses} />
      </div>
    ),
  },
  {
    column: "VOLTOTAL",
    type: "Long",
    description:
      "Total daily traffic of the segment. For the select link analysis, this is the traffic that only goes through the configured links and zones.",
  },
  {
    column: "VOLDT1, VOLDT2, VOLDT3, VOLDT4",
    type: "Integer",
    description:
      "Volume breakdowns grouped by day type DT1: Mon-Thu, DT2: Fri, DT3: Sat, DT4: Sun. (Not available for the select link analysis export)",
  },
  {
    column: "VOLH00-VOLH23",
    type: "Integer",
    description: "Volume breakdowns grouped by hour. (Not available for the select link analysis export.)",
  },
  {
    column: "VOLHW, VOLWH, VOLHO, VOLWO, VOLOW, VOLNHB",
    type: "Integer",
    description:
      "Volume breakdowns grouped by trip purpose H: Home, W: Work, O:Other, NHB: Non home based (Not available for the select link analysis export)",
  },
  {
    column: "VOLHBW, VOLHBO, VOLNHB",
    type: "Integer",
    description:
      "Volume breakdowns grouped by main purpose HBW: Home Based Work, HBO: Home Based Other, NHB: Non-Home Based (Not available for the select link analysis export.)",
  },
];
