import { Map as MapboxMap, Popup } from "mapbox-gl";
import { Dispatch, MutableRefObject, RefObject, SetStateAction } from "react";

import { RoadIntersectionPopupContentProps } from "features/road-intersections/RoadIntersectionPopupContent";

import { RoadIntersectionClusterVolumes, RoadIntersectionLevel, RoadIntersectionVolumes } from "types";

import { initUserInteractionsWithMap } from "./userInteractions";
import { initVolumesSources } from "./volumes";

export const getRoadIntersectionHandlers = (
  map: MapboxMap,
  draw: any,
  volumesDetails: RoadIntersectionVolumes,
  clusterVolumesDetails: RoadIntersectionClusterVolumes,
  levels: RoadIntersectionLevel[],
  roadIntersectionPopupRef: MutableRefObject<HTMLDivElement | null>,
  mapboxRoadIntersectionHoverPopupRef: RefObject<Popup>,
  setIntersectionPopupPropsRef: MutableRefObject<Dispatch<
    SetStateAction<RoadIntersectionPopupContentProps | null>
  > | null>,
  suppressRoadVolumePopupAndStatesRef: MutableRefObject<boolean>,
  selectedIntersectionIdRef: MutableRefObject<string | null>,
  selectedIntersectionsRef: MutableRefObject<string[] | null>,
  setVolumesLoading: Dispatch<SetStateAction<boolean>>,
  isScreenlineDrawModeRef: MutableRefObject<boolean>,
  isScreenlineEditorOpenRef: MutableRefObject<boolean>,
  setIsDrawModeActive: Dispatch<SetStateAction<boolean>>,
  setIsBaseIntersectionLevel: Dispatch<SetStateAction<boolean>>,
  setSelectedIntersectionId: (selectedIntersectionId: string | null) => void,
  setSelectedIntersections: (selectedIntersections: string[] | null) => void,
  closeRoadsAnalyticsPanelRef?: MutableRefObject<(() => void) | null>,
  clearSelectedIntersectionsRef?: MutableRefObject<(() => void) | null>,
) => {
  // @TODO reduce the number of arguments
  const { cleanUserInteractionsHandlers } = initUserInteractionsWithMap(
    map,
    draw,
    levels,
    roadIntersectionPopupRef,
    mapboxRoadIntersectionHoverPopupRef,
    setIntersectionPopupPropsRef,
    suppressRoadVolumePopupAndStatesRef,
    selectedIntersectionIdRef,
    selectedIntersectionsRef,
    isScreenlineDrawModeRef,
    isScreenlineEditorOpenRef,
    setIsDrawModeActive,
    setSelectedIntersectionId,
    setSelectedIntersections,
    closeRoadsAnalyticsPanelRef,
    clearSelectedIntersectionsRef,
  );

  const { cleanVolumesHandlers, updateRoadsIntersectionVolumes } = initVolumesSources(
    map,
    volumesDetails,
    clusterVolumesDetails,
    levels,
    setVolumesLoading,
    setIsBaseIntersectionLevel,
  );

  return {
    cleanRoadIntersectionHandlers: () => {
      cleanUserInteractionsHandlers();
      cleanVolumesHandlers();
    },
    updateRoadsIntersectionVolumes,
  };
};
