import memoryStore, { MemoryStoreKeys } from "api/memoryStore";
import groupBy from "lodash/groupBy";
import mapValues from "lodash/mapValues";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import { MapLayerMouseEvent, MapboxGeoJSONFeature, Map as MapboxMap, PointLike, Popup } from "mapbox-gl";
import { Dispatch, MutableRefObject, SetStateAction } from "react";

import { closeAllMapboxPopups, getSelectedVolumeObject } from "features/map/utils";

import { RoadsHoverPopupProps, VolumePopupContentProps } from "components";

import {
  ExtendedDirectionalRoadsTileService,
  ExtendedNonDirectionalRoadsTileService,
  MeasureType,
  SelectedVolume,
  Volume,
} from "types";

import { SCREENLINES_INTERSECTIONS_LAYER_ID } from "../../../screenlines/map-data/layers";
import { ROADS_HAIRLINES_LAYER_ID, ROADS_SEGMENTS_LAYER_ID, ROADS_VOLUMES_LAYER_ID } from "../layers";
import { ROADS_SOURCE_ID } from "../sources";
import { getToFromPropertyFromTileserviceIfAvailable } from "./utils";

const RADIUS_FOR_SEGMENT_HIGHLIGHT_IN_PIXELS = 2;

export const initUserInteractionsWithMap = (
  map: MapboxMap,
  measureRef: React.RefObject<MeasureType>,
  tileService: ExtendedDirectionalRoadsTileService | ExtendedNonDirectionalRoadsTileService,
  popupRoadSegmentHoverRef: any,
  popupRoadVolumesRef: any,
  mapboxVolumesPopupRef: any,
  setRoadHoverProps: React.RefObject<Dispatch<SetStateAction<RoadsHoverPopupProps | null>>>,
  blockClickEventRef: MutableRefObject<boolean | undefined> | undefined,
  // suppress flag comes from the road intersection handlers, hover over intersection is prioritized over hover over road segment
  suppressRoadVolumePopupAndStatesRef: MutableRefObject<boolean>,
  setVolumeProps: Dispatch<SetStateAction<Volume[]>>,
  setRoadsVolumesRef: React.RefObject<Dispatch<SetStateAction<VolumePopupContentProps | null>>>,
  updateRoadsPopupCounts: React.MutableRefObject<
    ((selectedVolume: SelectedVolume | null, volumesProps?: any[]) => void) | null
  >,
  setSelectedRoadVolume?: (selectedRoadVolume: SelectedVolume | null) => void,
  setSelectedRoadVolumeId?: (selectedRoadVolumeId: string) => void,
  closeRoadIntersectionsAnalyticsPanelRef?: MutableRefObject<(() => void) | null>,

  // select link specific args
  setSelectedLink?: React.MutableRefObject<(selectedRoadVolume: SelectedVolume, selectedRoadVolumeId: string) => void>,
  isSelectLink?: boolean,
  isSelectLinkResults?: boolean,
) => {
  let selectedSegmentFeatures: null | MapboxGeoJSONFeature[] = null;
  let mapboxSegmentHoverPopup: Popup | null = null;
  let selectedRoadVolume: SelectedVolume | null = null;
  let hoveredSegments: Map<number, MapboxGeoJSONFeature> = new Map();
  let hoveredSegmentIdxFromPopup: number | null = null;

  const closeSegmentHoverPopup = () => {
    if (mapboxSegmentHoverPopup?.isOpen()) {
      mapboxSegmentHoverPopup.remove();
    }

    const popups = document.getElementsByClassName("mapbox-segment-hover-popup");

    if (popups.length > 0) {
      // we need it to remove the popup from the DOM if it was not removed by the mapbox
      popups[0].remove();
    }
  };

  const closeAllPopups = () => {
    closeAllMapboxPopups(map);

    if (mapboxSegmentHoverPopup) {
      mapboxSegmentHoverPopup.remove();
    }

    if (mapboxVolumesPopupRef.current && mapboxVolumesPopupRef.current.isOpen()) {
      mapboxVolumesPopupRef.current.remove();
    }

    if (popupRoadSegmentHoverRef.current) {
      popupRoadSegmentHoverRef.current.remove();
    }

    if (popupRoadVolumesRef.current) {
      popupRoadVolumesRef.current.remove();
    }
  };

  const hoverSegmentsUnderCursor = (e: MapLayerMouseEvent) => {
    const pointX = e.point.x;
    const pointY = e.point.y;

    // Set `bbox` as 25px rectangle area around the cursor
    const bbox = [
      [pointX - RADIUS_FOR_SEGMENT_HIGHLIGHT_IN_PIXELS, pointY - RADIUS_FOR_SEGMENT_HIGHLIGHT_IN_PIXELS],
      [pointX + RADIUS_FOR_SEGMENT_HIGHLIGHT_IN_PIXELS, pointY + RADIUS_FOR_SEGMENT_HIGHLIGHT_IN_PIXELS],
    ] as [PointLike, PointLike];

    // Find all features that intersect that bounding box
    const segmentFeatures = map
      .queryRenderedFeatures(bbox, {
        layers: [ROADS_SEGMENTS_LAYER_ID],
      })
      .filter((selectedFeature) => typeof selectedFeature.id === "number");

    if (segmentFeatures.length > 0) {
      segmentFeatures.forEach((segmentFeature) => {
        hoverSegment(segmentFeature);
      });

      updateSegmentsStateWithHover(e, [segmentFeatures[0]], true);
    }
  };

  const unhoverAllSegmentsExceptSelected = () => {
    if (hoveredSegments.size) {
      removeCursorStyle();

      hoveredSegments.forEach((segment, segmentId) => {
        if (selectedSegmentFeatures && selectedSegmentFeatures.length > 0) {
          const isSelected = selectedSegmentFeatures.some((selectedSegment) => selectedSegment.id === segmentId);

          if (!isSelected) {
            unhoverSegment(segmentId);
          }
        } else {
          unhoverSegment(segmentId);
        }
      });
    }
  };

  const hoverSegment = (segment: MapboxGeoJSONFeature) => {
    const segmentId = segment.id as number;

    map.setFeatureState(
      {
        source: ROADS_SOURCE_ID,
        sourceLayer: tileService.layerName,
        id: segmentId,
      },
      { hover: true },
    );

    hoveredSegments.set(segmentId, segment);
  };

  const unhoverSegment = (segmentId: number) => {
    map.setFeatureState(
      {
        source: ROADS_SOURCE_ID,
        sourceLayer: tileService.layerName,
        id: segmentId,
      },
      { hover: false },
    );

    hoveredSegments.delete(segmentId);
  };

  const updateSegmentsStateWithHover = (e: MapLayerMouseEvent, segments: MapboxGeoJSONFeature[], status: boolean) => {
    if (!map.isStyleLoaded() || !map.getSource(ROADS_SOURCE_ID)) return;

    segments.forEach((segment) => {
      if (status) {
        hoverSegment(segment);
      } else {
        unhoverSegment(segment.id as number);
      }
    });

    if (status && typeof setRoadHoverProps.current === "function") {
      const volumes = getVolumesPropsFromSegments(segments, tileService, map, e.lngLat.toArray());

      setRoadHoverProps.current({
        volume: volumes.find(
          (s) => s[tileService.fromToSegmentIndexField as "fromToIdx"] === segments[0].id,
        ) as unknown as Volume,
        isPedestriansMode: measureRef.current === MeasureType.PEDESTRIANS,
      });
    }
  };

  const showPopupForHoveredSegment = (e: MapLayerMouseEvent) => {
    if (popupRoadSegmentHoverRef) {
      const isSameLocation =
        mapboxSegmentHoverPopup &&
        mapboxSegmentHoverPopup.isOpen() &&
        e.lngLat.lat === mapboxSegmentHoverPopup.getLngLat().lat &&
        e.lngLat.lng === mapboxSegmentHoverPopup.getLngLat().lng;

      if (!isSameLocation) {
        closeSegmentHoverPopup();

        mapboxSegmentHoverPopup = new Popup({
          closeButton: false,
          closeOnClick: false,
          offset: 15,
        })
          .setLngLat(e.lngLat)
          .setDOMContent(popupRoadSegmentHoverRef.current as Node);

        mapboxSegmentHoverPopup.addClassName("mapbox-segment-hover-popup");
        mapboxSegmentHoverPopup.addTo(map);
      }
    }
  };

  const showPopupForSelectedSegment = (e: MapLayerMouseEvent) => {
    if (popupRoadVolumesRef.current) {
      mapboxVolumesPopupRef.current = new Popup({
        closeOnClick: false,
      })
        .setLngLat(e.lngLat)
        .setDOMContent(popupRoadVolumesRef.current as Node)
        .setOffset([0, -25])
        .addTo(map);

      // close the popup when the user clicks on the map
      mapboxVolumesPopupRef.current.on("close", function () {
        if (selectedSegmentFeatures && selectedSegmentFeatures.length > 0) {
          updateSegmentsStateWithHover(e, selectedSegmentFeatures, false);
          updateSegmentsStateWithSelected(selectedSegmentFeatures);
        }

        selectedSegmentFeatures = null;
        selectedRoadVolume = null;

        if (setSelectedRoadVolume) {
          setSelectedRoadVolume(null);
        }
      });
    }
  };

  const getSegmentFeatureByIndex = (segmentIdx: number) => {
    return map.queryRenderedFeatures(undefined, {
      layers: [ROADS_SEGMENTS_LAYER_ID],
      filter: ["in", tileService.fromToSegmentIndexField, segmentIdx],
    });
  };

  const showSystemDefaultCursor = () => {
    map.getCanvas().style.cursor = "default";
  };

  const removeCursorStyle = () => {
    map.getCanvas().style.cursor = "";
  };

  const handleMousemoveOnSegmentsLayer = (e: MapLayerMouseEvent) => {
    closeSegmentHoverPopup();

    // hover over intersection is prioritized over hover over road segment
    if (suppressRoadVolumePopupAndStatesRef.current) return;

    // add selected segment feature if it is not was added before
    if (selectedRoadVolume && !selectedSegmentFeatures) {
      selectedSegmentFeatures = getSegmentFeatureByIndex(selectedRoadVolume.ftSegmentIdx);
    }

    if (e.features && e.features.length > 0) {
      // Find the feature with the highest volume weight
      const hoveredFeature = e.features.sort((a, b) => b.state.volumeWeight - a.state.volumeWeight)[0];
      const hoveredFeatureId = hoveredFeature?.id;

      unhoverAllSegmentsExceptSelected();

      // We should avoid screenlines intersections layer or if there is no hovered feature
      if (hoveredFeature.layer.id === SCREENLINES_INTERSECTIONS_LAYER_ID || !hoveredFeatureId) return;

      if (
        hoveredFeatureId !== selectedSegmentFeatures?.[0]?.id &&
        memoryStore.getItem(MemoryStoreKeys.ROADS_SEGMENT_FROM_TO_INDEXES)?.has(hoveredFeatureId)
      ) {
        hoverSegmentsUnderCursor(e);
        showPopupForHoveredSegment(e);
      }

      showSystemDefaultCursor();
    }
  };

  const handleMouseleaveOnSegmentsLayer = () => {
    closeSegmentHoverPopup();
    unhoverAllSegmentsExceptSelected();
  };

  const handleMouseMoveOnVolumesLayer = () => {
    showSystemDefaultCursor();
  };

  const handleMouseLeaveVolumesLayer = () => {
    removeCursorStyle();
  };

  const handleMouseClickOnSegmentsLayer = (e: MapLayerMouseEvent) => {
    closeAllPopups();

    if (
      blockClickEventRef?.current ||
      suppressRoadVolumePopupAndStatesRef.current ||
      !map.getLayer(ROADS_SEGMENTS_LAYER_ID) ||
      !map.getLayoutProperty(ROADS_SEGMENTS_LAYER_ID, "visibility")
    ) {
      return;
    }

    // remove the hover state from the segments if they were hovered before
    if (selectedSegmentFeatures && selectedSegmentFeatures.length > 0) {
      updateSegmentsStateWithHover(e, selectedSegmentFeatures, false);
    }

    // Close road intersections analytics panel, if any
    closeRoadIntersectionsAnalyticsPanelRef?.current?.();

    selectedSegmentFeatures = Array.from(hoveredSegments.values());

    if (selectedSegmentFeatures && selectedSegmentFeatures.length > 0) {
      updateSegmentsStateWithHover(e, selectedSegmentFeatures, true);

      const volumesProps = getVolumesPropsFromSegments(selectedSegmentFeatures, tileService, map, e.lngLat.toArray());
      const selectedVolume = volumesProps.find(
        (s) => s[tileService.fromToSegmentIndexField as "fromToIdx"] === selectedSegmentFeatures?.[0].id,
      ) as unknown as Volume;
      const volumeObj = getSelectedVolumeObject(selectedVolume);

      // select the segment by clicking on it
      if (setSelectedRoadVolume && setSelectedRoadVolumeId) {
        setSelectedRoadVolumeId(volumeObj.ftSegmentId); // select the "from to" direction by clicking on the segment
        selectedRoadVolume = volumeObj;
        setSelectedRoadVolume(volumeObj);
      }

      // update the popup with the volumes
      if (typeof updateRoadsPopupCounts.current === "function") {
        updateRoadsPopupCounts.current(volumeObj, volumesProps);
      }

      showPopupForSelectedSegment(e);

      // highlight the selected segment on the map
      if (selectedRoadVolume) {
        map.setFeatureState(
          {
            source: ROADS_SOURCE_ID,
            sourceLayer: tileService.layerName,
            id: selectedRoadVolume.ftSegmentIdx,
          },
          { selectHighlight: true, hover: true },
        );
      }
    }
  };

  const getUniqSegments = (
    segments: MapboxGeoJSONFeature[],
    tileService: ExtendedDirectionalRoadsTileService | ExtendedNonDirectionalRoadsTileService,
  ) => {
    return uniqBy(segments, "id").map((s) => {
      const volumes = memoryStore.getItem(
        isSelectLinkResults ? MemoryStoreKeys.SELECT_LINK_SEGMENT_VOLUMES : MemoryStoreKeys.ROADS_SEGMENT_VOLUMES,
      ) as Map<number, number>;
      const fromToSegmentId = s.properties?.[tileService.fromToSegmentIdField];
      const fromToSegmentIdx = s.properties?.[tileService.fromToSegmentIndexField];
      const toFromSegmentIdField = getToFromPropertyFromTileserviceIfAvailable(tileService, "toFromSegmentIdField");
      const toFromSegmentIndexField = getToFromPropertyFromTileserviceIfAvailable(
        tileService,
        "toFromSegmentIndexField",
      );
      const toFromSegmentId = toFromSegmentIdField ? s.properties?.[toFromSegmentIdField] : undefined;
      const toFromSegmentIdx = toFromSegmentIndexField ? s.properties?.[toFromSegmentIndexField] : undefined;

      return {
        ...s.properties,
        fromToId: fromToSegmentId,
        fromToIdx: fromToSegmentIdx,
        toFromId: toFromSegmentId,
        toFromIdx: toFromSegmentIdx,
        volumeFT: volumes.get(fromToSegmentIdx) || 0,
        volumeTF: volumes.get(toFromSegmentIdx) || 0,
      } as {
        fromToId: string;
        fromToIdx: number;
        toFromId: string;
        toFromIdx: number;
        volumeFT: number;
        volumeTF: number;
      } & MapboxGeoJSONFeature;
    });
  };

  const getVolumesPropsFromSegments = (
    segments: MapboxGeoJSONFeature[],
    tileService: ExtendedDirectionalRoadsTileService | ExtendedNonDirectionalRoadsTileService,
    map: MapboxMap,
    lngLat: number[],
  ) => {
    const uniqSegments = getUniqSegments(segments, tileService);
    const volumesPropsOrderedByStreetName = orderBy(uniqSegments, ["st_name"], ["asc"]);

    const visibleFeatureIndexes = volumesPropsOrderedByStreetName.map((s) => s.fromToIdx);
    // get only visible features
    const visibleFeatures =
      visibleFeatureIndexes.length > 0
        ? map.queryRenderedFeatures(undefined, {
            layers: [ROADS_VOLUMES_LAYER_ID],
            filter: ["in", tileService.fromToSegmentIndexField, ...visibleFeatureIndexes],
          })
        : [];

    const featureWeights: Record<number, number> = {};

    if (visibleFeatures.length > 0) {
      visibleFeatures.forEach((f: MapboxGeoJSONFeature) => {
        const { geometry, id } = f;

        if (id && (geometry.type === "LineString" || geometry.type === "MultiLineString")) {
          const latitudes = [...geometry.coordinates].map((c) => c[1]) as number[];

          featureWeights[id as number] = Math.max(0, ...latitudes);
        }
      });
    }

    const groupedVolumesProps = groupBy(volumesPropsOrderedByStreetName, "st_name");

    const result = Object.values(
      mapValues(groupedVolumesProps, (group) => {
        return [...group].map((el) => ({
          ...el,
          weight: featureWeights[el.fromToIdx],
          lngLat,
        }));
      }),
    ).flat();

    return result.length > 0 ? result : volumesPropsOrderedByStreetName;
  };

  const updateSegmentsStateWithSelected = (segments: MapboxGeoJSONFeature[]) => {
    if (!map.isStyleLoaded() || !map.getSource(ROADS_SOURCE_ID)) return;

    segments.forEach((segment) => {
      map.removeFeatureState(
        {
          source: ROADS_SOURCE_ID,
          sourceLayer: tileService.layerName,
          id: segment.id,
        },
        "selectHighlight",
      );
    });
  };

  const handleHoverSegmentFromPopup = (segmentIdx: number | null) => {
    if (map?.getLayer(ROADS_SEGMENTS_LAYER_ID)) {
      if (hoveredSegmentIdxFromPopup) {
        map.removeFeatureState(
          {
            source: ROADS_SOURCE_ID,
            sourceLayer: tileService.layerName,
            id: hoveredSegmentIdxFromPopup,
          },
          "hoverHighlight",
        );
      }

      if (segmentIdx) {
        map.setFeatureState(
          {
            source: ROADS_SOURCE_ID,
            sourceLayer: tileService.layerName,
            id: segmentIdx,
          },
          { hoverHighlight: true },
        );
      }
    }

    hoveredSegmentIdxFromPopup = segmentIdx;
  };

  const handleClickSegmentFromPopup = (selectedVolume: SelectedVolume, id: string, volumesProps?: Volume[]) => {
    if (!selectedVolume || !id || !(setSelectedRoadVolumeId || (isSelectLink && setSelectedLink))) {
      return;
    }

    if (isSelectLink && setSelectedLink) {
      setSelectedLink.current(selectedVolume, id);
    } else if (setSelectedRoadVolumeId) {
      setSelectedRoadVolumeId(id);
    }

    // This case probably won't be easy to have, but close road intersections analytics panel, if any
    closeRoadIntersectionsAnalyticsPanelRef?.current?.();

    if (map && JSON.stringify(selectedRoadVolume) !== JSON.stringify(selectedVolume)) {
      if (selectedRoadVolume) {
        map.removeFeatureState(
          {
            source: ROADS_SOURCE_ID,
            sourceLayer: tileService.layerName,
            id: selectedRoadVolume.ftSegmentIdx,
          },
          "selectHighlight",
        );
      }

      setSelectedRoadVolume!(selectedVolume);
      selectedRoadVolume = selectedVolume;

      if (selectedVolume) {
        map.setFeatureState(
          {
            source: ROADS_SOURCE_ID,
            sourceLayer: tileService.layerName,
            id: selectedVolume.ftSegmentIdx,
          },
          { selectHighlight: true },
        );
      }

      if (typeof updateRoadsPopupCounts.current === "function") {
        updateRoadsPopupCounts.current(selectedVolume, volumesProps);
      }
    }
  };

  updateRoadsPopupCounts.current = (selectedVolume: SelectedVolume | null, volumesProps?: any[]) => {
    if (typeof setRoadsVolumesRef.current === "function") {
      const volumesPropsList =
        volumesProps || getVolumesPropsFromSegments(selectedSegmentFeatures!, tileService, map, []);

      setVolumeProps(volumesPropsList);

      setRoadsVolumesRef.current({
        selectedVolume,
        volume: volumesPropsList,
        isPedestriansMode: measureRef.current === MeasureType.PEDESTRIANS,
        onHover: handleHoverSegmentFromPopup,
        onClick: handleClickSegmentFromPopup,
      });
    }
  };

  map.on("mousemove", [ROADS_SEGMENTS_LAYER_ID, SCREENLINES_INTERSECTIONS_LAYER_ID], handleMousemoveOnSegmentsLayer);
  map.on("mouseleave", ROADS_SEGMENTS_LAYER_ID, handleMouseleaveOnSegmentsLayer);
  map.on("mousemove", [ROADS_HAIRLINES_LAYER_ID, ROADS_VOLUMES_LAYER_ID], handleMouseMoveOnVolumesLayer);
  map.on("mouseleave", [ROADS_HAIRLINES_LAYER_ID, ROADS_VOLUMES_LAYER_ID], handleMouseLeaveVolumesLayer);

  if (!isSelectLinkResults) {
    map.on("click", ROADS_SEGMENTS_LAYER_ID, handleMouseClickOnSegmentsLayer);
  }

  return {
    cleanUserInteractionsHandlers: () => {
      closeAllPopups();

      map.off(
        "mousemove",
        [ROADS_SEGMENTS_LAYER_ID, SCREENLINES_INTERSECTIONS_LAYER_ID],
        handleMousemoveOnSegmentsLayer,
      );
      map.off("mouseleave", ROADS_SEGMENTS_LAYER_ID, handleMouseleaveOnSegmentsLayer);
      map.off("mousemove", [ROADS_HAIRLINES_LAYER_ID, ROADS_VOLUMES_LAYER_ID], handleMouseMoveOnVolumesLayer);
      map.off("mouseleave", [ROADS_HAIRLINES_LAYER_ID, ROADS_VOLUMES_LAYER_ID], handleMouseLeaveVolumesLayer);

      if (!isSelectLinkResults) {
        map.off("click", ROADS_SEGMENTS_LAYER_ID, handleMouseClickOnSegmentsLayer);
      }
    },
  };
};
