import { Grid, ListItemIcon, ListItemText, MenuItem, Stack, SxProps } from "@mui/material";
import { MapControlContainer, TextField } from "components_new";
import { LngLatBounds, Map } from "mapbox-gl";
import React, { FC } from "react";

import { MAPBOX_STYLE_OPTIONS, MapBoxStyles } from "features/map/baseMapStyles";

import { useAppDispatch, useAppSelector } from "hooks";

import { mapActions } from "store/sections/map";

interface BaseMapStylesProps {
  map: Map | null;
  disabled?: boolean;
  sx?: SxProps;
}

export const BaseMapStyles: FC<BaseMapStylesProps> = ({ map, disabled, sx }) => {
  const dispatch = useAppDispatch();
  const baseMapStyle = useAppSelector((state) => state.map.baseMapStyle);

  const changeBaseMapStyle = (style: MapBoxStyles) => {
    const bounds = map?.getBounds().toArray();

    if (bounds) {
      dispatch(mapActions.setMapBounds(bounds as unknown as LngLatBounds));
    }

    dispatch(mapActions.setBaseMapStyle(style));
  };

  const handleChangeBaseMapStyle = (style: MapBoxStyles) => {
    if (!map) {
      return;
    }

    // We need to wait for the map to be idle before changing the style
    map.once("idle", () => {
      changeBaseMapStyle(style);
    });

    // We need to make the map do something to trigger the idle event if it's already idle
    map.triggerRepaint();
  };

  return (
    <MapControlContainer title="Base Map">
      <Stack spacing={1} marginTop={1} padding={1} sx={sx}>
        <TextField label="Styles" select disabled={disabled} value={baseMapStyle}>
          {MAPBOX_STYLE_OPTIONS.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              onClick={() => handleChangeBaseMapStyle(option.value as MapBoxStyles)}
            >
              <Grid container alignItems={"center"}>
                <ListItemIcon sx={{ minWidth: "0 !important", marginRight: "8px" }}>{option.icon}</ListItemIcon>
                <ListItemText
                  primary={option.label}
                  primaryTypographyProps={{ noWrap: true, fontSize: 14 }}
                  sx={{ margin: 0 }}
                />
              </Grid>
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    </MapControlContainer>
  );
};
