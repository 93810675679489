import { styled } from "@mui/material";
import { MeasureSummaryResults } from "api/analytics";
import { AnalyticsContainer, CircularProgress } from "components_new";
import { FC, MutableRefObject, memo, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { AnalyticsBreakdowns } from "features/analytics/AnalyticsBreakdowns";
import { getCurrentMeasure } from "features/filters/utils";

import { RightSidebarPanel } from "components";

import { useAppSelector } from "hooks";

import { DataState } from "store/interfaces";

import { AggregationFunction, MapVisualizationType } from "types";

interface RoadIntersectionsAnalyticsPanelProps {
  closeRoadIntersectionsAnalyticsPanelRef: MutableRefObject<(() => void) | null>;
}

const RightSidebarLoaderWrapper = styled("div")`
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const getTitle = (isLoading: boolean, selectedIntersections: string[] | null): string => {
  return isLoading
    ? "Loading..."
    : selectedIntersections
    ? `Selection Details (${selectedIntersections.length})`
    : "Intersection Details";
};

const getSubtitle = (
  summary: MeasureSummaryResults | undefined,
  selectedIntersectionId: string | null,
  fn: AggregationFunction,
): string => {
  return `Annual average daily volume ${summary ? (summary.filteredTotal ?? 0).toLocaleString("en-US") : "..."}${
    selectedIntersectionId ? "" : ` (selection ${fn})`
  }`;
};

export const RoadIntersectionsAnalyticsPanel: FC<RoadIntersectionsAnalyticsPanelProps> = memo(
  ({ closeRoadIntersectionsAnalyticsPanelRef }) => {
    const [searchParams] = useSearchParams();

    const measure = useAppSelector((state) => state.filters.measure);

    const intersectionVolumeDetails = useAppSelector((state) => state.roadIntersections.intersectionVolumeDetails);
    const selectedIntersectionId = useAppSelector((state) => state.roadIntersections.selectedIntersectionId);
    const selectedIntersections = useAppSelector((state) => state.roadIntersections.selectedIntersections);
    const intersectionVolumesState = useAppSelector((state) => state.roadIntersections.intersectionVolumes.state);
    const aggregationFunction = useAppSelector((state) => state.roadIntersections.aggregationFunction);
    const roadMeasures = useAppSelector((state) => state.analytics.roadsMetadata.data?.measures);

    const currentRoadMeasure = useMemo(() => getCurrentMeasure(roadMeasures, measure), [roadMeasures, measure]);
    const roadsMetadataDimensions = useMemo(() => currentRoadMeasure?.dimensions || [], [currentRoadMeasure]);

    const mode = useMemo(() => searchParams.get("mode") as MapVisualizationType, [searchParams]);
    const isLoading = useMemo(() => {
      return intersectionVolumesState === DataState.LOADING || intersectionVolumeDetails.state === DataState.LOADING;
    }, [intersectionVolumesState, intersectionVolumeDetails.state]);

    const existsSelection = useMemo(
      () => Boolean(selectedIntersectionId || selectedIntersections),
      [selectedIntersectionId, selectedIntersections],
    );

    const isShowRightSidebar = useMemo(
      () =>
        Boolean(
          existsSelection &&
            intersectionVolumeDetails.state === DataState.AVAILABLE &&
            intersectionVolumeDetails?.data &&
            mode === MapVisualizationType.ROADS,
        ),
      [mode, existsSelection, intersectionVolumeDetails],
    );

    const isDetailsLoading = useMemo(() => Boolean(existsSelection && isLoading), [existsSelection, isLoading]);

    const handleClosePanel = () => {
      closeRoadIntersectionsAnalyticsPanelRef?.current?.();
    };

    const breakdowns = useMemo(
      () =>
        intersectionVolumeDetails.data && existsSelection ? intersectionVolumeDetails.data?.summary.breakdowns : [],
      [intersectionVolumeDetails.data, existsSelection],
    );

    return (
      <RightSidebarPanel
        title={getTitle(isDetailsLoading, selectedIntersections)}
        subtitle={getSubtitle(intersectionVolumeDetails.data?.summary, selectedIntersectionId, aggregationFunction)}
        onClose={handleClosePanel}
        isOpen={isShowRightSidebar || isDetailsLoading}
      >
        {!isDetailsLoading && existsSelection && roadsMetadataDimensions && intersectionVolumeDetails.data ? (
          <AnalyticsContainer heightOperand={102}>
            <AnalyticsBreakdowns
              breakdowns={breakdowns}
              dimensions={roadsMetadataDimensions}
              containersIdPrefix="road_intersections"
            />
          </AnalyticsContainer>
        ) : null}
        {isDetailsLoading && (
          <RightSidebarLoaderWrapper>
            <CircularProgress />
          </RightSidebarLoaderWrapper>
        )}
      </RightSidebarPanel>
    );
  },
);
