import { Box, Chip, Link, Typography, styled } from "@mui/material";
import { ReleaseDetails } from "api/account";
import { Button } from "components_new";
import React from "react";

import { formatCreatedTimeShort } from "utils/format";

import { ReleaseDescription } from "./ReleaseInfo";
import { ReleaseNote } from "./ReleaseNote";

const ReleaseNoteWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  border: solid var(--color-gray-200) 1px;
  border-radius: 1rem;
  padding: 1rem;
`;

const ReleaseNoteHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  border-bottom: solid var(--color-gray-200) 1px;
`;

const ReleaseNoteContent = styled(Box)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  padding-right: 1rem;
  max-height: 400px;
`;

export const ReleaseNoteBox = ({
  releaseDetails,
  triggerGAEvent,
}: {
  releaseDetails: ReleaseDetails;
  triggerGAEvent: (event: string) => void;
}) => (
  <ReleaseNoteWrapper>
    <ReleaseNoteHeader>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
        <Typography variant="h6">{releaseDetails.name}</Typography>
        <Chip
          size={"small"}
          variant={"outlined"}
          color={"default"}
          label={formatCreatedTimeShort(new Date(releaseDetails.releasedAt))}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          width: "100%",
          paddingBottom: "0.5rem",
        }}
      >
        <ReleaseDescription color="textSecondary">{releaseDetails.description}</ReleaseDescription>
        {releaseDetails.announcementUrl ? (
          <Link href={releaseDetails.announcementUrl} target="_blank" sx={{ display: "flex", textDecoration: "none" }}>
            <Button
              size="small"
              color="secondary"
              variant="text"
              sx={{ p: 0, m: 0 }}
              onClick={() => triggerGAEvent("Release marketing post")}
            >
              Blog post
            </Button>
          </Link>
        ) : null}
      </Box>
    </ReleaseNoteHeader>
    <ReleaseNoteContent>
      <ReleaseNote notes={releaseDetails?.releaseNotes?.newFeatures} title="New Features" />
      <ReleaseNote notes={releaseDetails?.releaseNotes?.improvements} title="Improvements" />
      <ReleaseNote notes={releaseDetails?.releaseNotes?.notes} title="Notes" />
    </ReleaseNoteContent>
  </ReleaseNoteWrapper>
);
