import { ExpandLess } from "@mui/icons-material";
import { Box, Typography, TypographyProps, styled } from "@mui/material";
import { Button, IconButton } from "components_new";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "hooks";

import { globalActions } from "store/sections/global";

const ReleaseOverlay = styled(Box)`
  position: fixed;
  bottom: 70px;
  right: 0;
  height: 125px;
  background: rgba(0, 0, 0, 0.7);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 1000;
`;

const ReleaseAvailableOverlay = styled(ReleaseOverlay)`
  width: 380px;
`;

const ReleaseAvailableContent = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  pointer-events: auto;
  width: 100%;
`;

const InfoPopoverHeader = styled((props: TypographyProps) => (
  <Typography fontSize={12} color="white" fontWeight="bold" mt={1} mb={1} {...props} />
))``;

const InfoPopoverContent = styled((props: TypographyProps) => (
  <Typography fontSize={11} color="white" mb={1.5} {...props} />
))``;

const CollapseButton = styled(IconButton)`
  position: absolute;
  top: 2px;
  right: 3px;
  color: white;
`;

const CollapsedReleaseAvailableOverlay = styled(ReleaseOverlay)`
  width: 27px;
  cursor: pointer;
`;

const VerticalText = styled(Typography)`
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  color: white;
  font-size: 11px;
  font-weight: bold;
  text-align: left;
  margin: 25px auto 0 auto;
`;

export const ReleaseAvailable: React.FC = memo(() => {
  const dispath = useDispatch();

  const currentRelease = useAppSelector((state) => state.global.currentRelease);

  const [releaseId, setReleaseId] = useState<number | null>(null);
  const [reloadPageCounter, setReloadPageCounter] = useState(0);
  const [isNewReleaseRequiresReloading, setIsNewReleaseRequiresReloading] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  // Check for updates every 5 minutes
  const interval = setInterval(() => {
    dispath(globalActions.fetchCurrentRelease());
  }, 1000 * 60 * 5); // 5 minutes

  const showAvailableReleaseAndRemoveInterval = useCallback(() => {
    setIsNewReleaseRequiresReloading(true);
    clearInterval(interval);
  }, [interval]);

  // Fetch current release on mount
  useEffect(() => {
    dispath(globalActions.fetchCurrentRelease());
  }, [dispath]);

  useEffect(() => {
    if (currentRelease.data) {
      // If releaseId is not set, set it to the current release id
      if (!releaseId) {
        setReleaseId(currentRelease.data.id);
        setReloadPageCounter(currentRelease.data.actions?.reloadPage ?? 0);

        return;
      }

      const isNewRelease = releaseId !== currentRelease.data.id;

      // If the release id is different, set the new release id and show the overlay if the release requires a reload
      if (isNewRelease || reloadPageCounter !== currentRelease.data.actions?.reloadPage) {
        setReleaseId(currentRelease.data.id);
        setReloadPageCounter(currentRelease.data.actions?.reloadPage ?? 0);

        if (currentRelease.data.actions?.reloadPage) {
          showAvailableReleaseAndRemoveInterval();
        }
      }
    }

    return () => clearInterval(interval);
  }, [currentRelease.data, releaseId, interval, reloadPageCounter, showAvailableReleaseAndRemoveInterval]);

  const handleReload = () => {
    window.location.reload();
  };

  return isNewReleaseRequiresReloading ? (
    isCollapsed ? (
      <CollapsedReleaseAvailableOverlay onClick={() => setIsCollapsed(false)}>
        <CollapseButton>
          <ExpandLess fontSize="small" sx={{ transform: "rotate(-90deg)" }} />
        </CollapseButton>
        <VerticalText>Update available</VerticalText>
      </CollapsedReleaseAvailableOverlay>
    ) : (
      <ReleaseAvailableOverlay>
        <ReleaseAvailableContent p={0.5}>
          <CollapseButton onClick={() => setIsCollapsed(true)}>
            <Typography fontSize={10}>Hide</Typography>
            <ExpandLess fontSize="small" sx={{ transform: "rotate(90deg)" }} />
          </CollapseButton>
          <InfoPopoverHeader>Patterns has been updated</InfoPopoverHeader>
          <InfoPopoverContent>
            We recommend to reload the page to get the latest features and improvements. Make sure to save any changes
            that you want to keep.
          </InfoPopoverContent>
          <Button color="info" onClick={handleReload}>
            Reload Page
          </Button>
        </ReleaseAvailableContent>
      </ReleaseAvailableOverlay>
    )
  ) : null;
});
