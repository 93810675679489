import styled from "@emotion/styled";
import { Box, CardMedia, Typography } from "@mui/material";
import { Button } from "components_new";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

export interface LearnMoreCardProps {
  item: {
    event: string;
    url: string;
    urlText: string;
    title: string;
    imageUrl: string;
    buttonText?: string;
  };
  triggerGAEvent: (event: string) => void;
}

const LearnMoreCardContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isCardHasButton",
})<{ isCardHasButton: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: solid var(--color-gray-200) 1px;
  border-radius: 1rem;
  overflow: hidden;
  :hover {
    cursor: ${(props) => (props.isCardHasButton ? "default" : "pointer")};
    border: ${(props) =>
      props.isCardHasButton ? "solid var(--color-gray-200) 1px" : "solid var(--color-gray-400) 1px"};
  }
  max-height: 280px;
`;

const LearnMoreTitleContainer = styled(Box)`
  display: flex;
  padding: 0.75rem;
  gap: 0.5rem;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  a {
    color: var(--color-text);
  }
  a:hover {
    color: var(--color-text);
  }
`;

const LearnMoreTitle = styled(Typography)`
  margin-bottom: 1rem;
  text-align: center;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 0.75rem;
  color: var(--color-gray-500);
`;

export const LearnMoreCard: FC<LearnMoreCardProps> = ({ item, triggerGAEvent }) => {
  const navigate = useNavigate();
  const urlIsExternal = item.url.startsWith("http");
  const isCardHasButton = Boolean(item.buttonText);

  const handleCardItemClick = () => {
    triggerGAEvent(item.event);

    if (urlIsExternal) {
      window.open(item.url, "_black"); // open in new tab
    } else {
      navigate(item.url);
    }
  };

  return (
    <LearnMoreCardContainer
      isCardHasButton={isCardHasButton}
      onClick={isCardHasButton ? undefined : handleCardItemClick}
    >
      <CardMedia sx={{ width: "100%", height: "auto" }} component="img" image={item.imageUrl} alt={item.title} />
      <LearnMoreTitleContainer>
        <LearnMoreTitle variant="subtitle1">{item.title}</LearnMoreTitle>
        <Typography sx={{ fontWeight: "500", fontSize: "0.9rem" }}>{item.urlText}</Typography>
        {item.buttonText && (
          <Button
            sx={{ marginLeft: "auto", marginRight: "0" }}
            size="medium"
            color="primary"
            onClick={handleCardItemClick}
          >
            {item.buttonText}
          </Button>
        )}
      </LearnMoreTitleContainer>
    </LearnMoreCardContainer>
  );
};
