import { styled } from "@mui/material";
import { IconButton } from "components_new";

import { FlexContainer } from "components";

export const DrawControlsWrapper = styled("div")(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}));

export const DrawControls = styled(FlexContainer)`
  justify-content: space-around;
  background: var(--color-text-field-gray);
  border-radius: 8px;
  padding: 15px 5px;
  margin-top: 0.5rem;
`;

export const DrawIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "selected",
})<{ selected?: boolean }>(({ theme, selected }) => ({
  width: "35px",
  height: "35px",
  borderRadius: "8px",
  backgroundColor: selected ? "#000000" : "#ffffff",
  boxShadow: "var(--box-shadow-md)",
  border: `1px solid ${theme.palette.divider}`,

  "&:hover": {
    backgroundColor: selected ? "#000000" : "#ffffff",
  },

  "& svg": {
    height: "20px",
    width: "20px",

    color: selected ? "#ffffff" : "#000000",
  },

  "&.Mui-disabled svg": {
    color: theme.palette.text.disabled,
  },
}));
