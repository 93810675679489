import { Layer } from "mapbox-gl";
import { MutableRefObject } from "react";

import theme from "theme";

import { RoadIntersectionLevel } from "types";

import { ROAD_INTERSECTIONS_SOURCE_ID } from "./sources";

export const ROAD_INTERSECTIONS_LAYER_ID = "ROAD_INTERSECTIONS_LAYER_ID";

export const getRoadIntersectionLayerNameForLevel = (level: RoadIntersectionLevel) => {
  return level.maxRadius ? `${ROAD_INTERSECTIONS_LAYER_ID}_${level.maxRadius}` : ROAD_INTERSECTIONS_LAYER_ID;
};

export const getRoadIntersectionSourceLayerNameForLevel = (level: RoadIntersectionLevel) => {
  return level.tileService.layerName;
};

const getLevelPalette = (level: RoadIntersectionLevel) =>
  level.maxRadius
    ? theme.palette.roadIntersections.clusters
    : theme.palette.roadIntersections.nodes;

export const getRoadIntersectionsLayerCircleRadiusExpression = (
  level: RoadIntersectionLevel,
  maxVolume: number,
  minZoomLevel: number,
  maxZoomLevel: number,
  widthFactor: number,
) => {
  const palette = getLevelPalette(level);

  return [
    // interpolate doesn't support variables apparently, so this needs to be inlined and then the whole expression updated with new values
    "interpolate",
    ["linear"],
    ["zoom"],
    minZoomLevel,
    [
      "case",
      [">", ["coalesce", ["feature-state", "volume"], 0], 0],
      [
        "*",
        ["coalesce", ["number", ["literal", widthFactor]], 1.0],
        [
          "interpolate",
          ["linear"],
          ["sqrt", ["feature-state", "volume"]],
          0,
          [
            "*",
            ["case", ["boolean", ["feature-state", "hover"], false], palette.hoverSizeFactorAtMinZoom, 1],
            palette.minimumSizeAtMinZoom,
          ],
          Math.sqrt(maxVolume),
          [
            "*",
            ["case", ["boolean", ["feature-state", "hover"], false], palette.hoverSizeFactorAtMaxZoom, 1],
            palette.maximumSizeAtMinZoom,
          ],
        ],
      ],
      0, // hide while volumes are updating
    ],
    maxZoomLevel,
    [
      "case",
      [">", ["coalesce", ["feature-state", "volume"], 0], 0],
      [
        "*",
        ["coalesce", ["number", ["literal", widthFactor]], 1.0],
        [
          "interpolate",
          ["linear"],
          ["sqrt", ["feature-state", "volume"]],
          0,
          [
            "*",
            ["case", ["boolean", ["feature-state", "hover"], false], palette.hoverSizeFactorAtMaxZoom, 1],
            palette.minimumSizeAtMaxZoom,
          ],
          Math.sqrt(maxVolume),
          [
            "*",
            ["case", ["boolean", ["feature-state", "hover"], false], palette.hoverSizeFactorAtMaxZoom, 1],
            palette.maximumSizeAtMaxZoom,
          ],
        ],
      ],
      0, // hide while volumes are updating
    ],
  ];
};

export const getRoadIntersectionLayerCircleOpacityExpression = (level: RoadIntersectionLevel, opacityFactor: number = 1) => {
  const palette = getLevelPalette(level);

  return [
    "case",
    ["<=", ["coalesce", ["feature-state", "volume"], 0], 0],
    0.0, // fully transparent when no volume data, always
    [
      "*",
      [
        "case",
        ["boolean", ["feature-state", "selectHighlight"], false],
        palette.selectionOpacity,
        [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          palette.hoverOpacity,
          palette.opacity
        ],
      ],
      opacityFactor,
    ]
  ]
};

export const getRoadIntersectionsLayerCircleColorExpression = (level: RoadIntersectionLevel, maxVolume: number) => {
  const palette = getLevelPalette(level);

  return [
    "case",
    ["boolean", ["feature-state", "selectHighlight"], false],
    palette.selectionColor,
    ["boolean", ["feature-state", "hover"], false],
    palette.hoverColor,
    [
      "interpolate",
      ["linear"],
      ["sqrt", ["coalesce", ["feature-state", "volume"], 0]],
      0,
      palette.minVolumeColor,
      Math.sqrt(maxVolume),
      palette.maxVolumeColor,
    ],
  ];
};

export const getRoadIntersectionsLayerCircleStrokeColorExpression = (level: RoadIntersectionLevel) => {
  const palette = getLevelPalette(level);

  return [
    "case",
    ["boolean", ["feature-state", "hover"], false], palette.hoverStrokeColor,
    [
      "case",
      ["boolean", ["feature-state", "selectHighlight"], false],
      palette.selectionStrokeColor,
      palette.strokeColor
    ]
  ];
};

export const getRoadIntersectionsLayerCircleStrokeWidthExpression = (level: RoadIntersectionLevel) => {
  const palette = getLevelPalette(level);

  return [
    "case",
    ["boolean", ["feature-state", "hover"], false], palette.hoverStrokeWidth,
    [
      "case",
      ["boolean", ["feature-state", "selectHighlight"], false],
      palette.selectionStrokeWidth,
      palette.strokeWidth
    ]
  ];
};

export const getRoadIntersectionNodeLayers = (
  showRoadIntersectionsRef: MutableRefObject<boolean | null>,
  minZoomLevel: number,
  maxZoomLevel: number,
  baseLevel: RoadIntersectionLevel | undefined,
  ids?: number[],
) => {
  if (!baseLevel) return [];
  return [
    {
      id: `${ROAD_INTERSECTIONS_LAYER_ID}`,
      type: "circle",
      source: ROAD_INTERSECTIONS_SOURCE_ID,
      "source-layer": baseLevel.tileService.layerName,
      paint: {
        "circle-color": getRoadIntersectionsLayerCircleColorExpression(baseLevel, 1),
        "circle-opacity": getRoadIntersectionLayerCircleOpacityExpression(baseLevel, 1.0),
        "circle-radius": getRoadIntersectionsLayerCircleRadiusExpression(baseLevel, 1, minZoomLevel, maxZoomLevel, 1.0),
        "circle-stroke-color": getRoadIntersectionsLayerCircleStrokeColorExpression(baseLevel),
        "circle-stroke-width": getRoadIntersectionsLayerCircleStrokeWidthExpression(baseLevel),
      },
      filter: ids ? ["in", baseLevel.tileService.idField, ...ids] : undefined,
      layout: {
        visibility: showRoadIntersectionsRef.current ? "visible" : "none",
      },
      minzoom: minZoomLevel,
    },
  ] as Layer[];
};

export const getRoadIntersectionClusterLayers = (
  showRoadIntersectionsRef: MutableRefObject<boolean | null>,
  clusterLevels: RoadIntersectionLevel[] | undefined,
  ids?: Map<number, Map<number, number>>,
) => {
  return clusterLevels
    ?.map((clusterLevel) => {
      if (!clusterLevel.maxRadius) return null;
      const level = clusterLevel.maxRadius;
      const clusterIds = Array.from(ids?.get(level)?.keys() || []);
      return {
        id: `${ROAD_INTERSECTIONS_LAYER_ID}_${clusterLevel.maxRadius}`,
        type: "circle",
        source: `${ROAD_INTERSECTIONS_SOURCE_ID}_${clusterLevel.maxRadius}`,
        "source-layer": clusterLevel.tileService.layerName,
        paint: {
          "circle-color": getRoadIntersectionsLayerCircleColorExpression(clusterLevel, 1),
          "circle-opacity": getRoadIntersectionLayerCircleOpacityExpression(clusterLevel, 1.0),
          "circle-radius": getRoadIntersectionsLayerCircleRadiusExpression(
            clusterLevel,
            1,
            clusterLevel.minZoomLevel,
            clusterLevel.maxZoomLevel,
            1.0,
          ),
          "circle-stroke-color": getRoadIntersectionsLayerCircleStrokeColorExpression(clusterLevel),
          "circle-stroke-width": getRoadIntersectionsLayerCircleStrokeWidthExpression(clusterLevel),
        },
        filter: clusterIds ? ["in", clusterLevel.tileService.idField, ...clusterIds] : undefined,
        layout: {
          visibility: showRoadIntersectionsRef.current ? "visible" : "none",
        },
        minzoom: clusterLevel.minZoomLevel,
        maxzoom: clusterLevel.maxZoomLevel,
      } as Layer;
    })
    .filter((layer) => !!layer) as Layer[];
};
