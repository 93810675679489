export const gatesShapeFileColumns = [
  {
    column: "gate_id",
    type: "Integer",
    description: "Numeric id assigned to gate at dataset creation",
  },
  {
    column: "descr",
    type: "Text",
    description: "User defined gate description",
  },
  {
    column: "gate_idseq",
    type: "Text",
    description:
      "Renumbered gate ID, mapped into 1-32,000 range together with zones, zone_idseq in zones file and gate_idseq are guaranteed to be distinct",
  },
  {
    column: "O_TOTAL",
    type: "Integer",
    description: "Total number of Average Daily Traffic (ADT) originating from this gate",
  },
  {
    column: "O_DT1",
    type: "Integer",
    description: "ADT (Average Daily Traffic) on Mondays-Thursdays originating from this gate",
  },
  {
    column: "O_DT2",
    type: "Integer",
    description: "ADT on Fridays originating from this gate",
  },
  {
    column: "O_DT3",
    type: "Integer",
    description: "ADT on Saturdays originating from this gate",
  },
  {
    column: "O_DT4",
    type: "Integer",
    description: "ADT on Sundays originating from this gate",
  },
  {
    column: "O_DP1",
    type: "Integer",
    description: "ADT between midnight and 6am originating from this gate",
  },
  {
    column: "O_DP2",
    type: "Integer",
    description: "ADT between 6am and 10am originating from this gate",
  },
  {
    column: "O_DP3",
    type: "Integer",
    description: "ADT between 10am and 3pm originating from this gate",
  },
  {
    column: "O_DP4",
    type: "Integer",
    description: "ADT between 3pm and 7pm originating from this gate",
  },
  {
    column: "O_DP5",
    type: "Integer",
    description: "ADT between 7pm and midnight originating from this gate",
  },
  {
    column: "O_HW",
    type: "Integer",
    description: "ADT with Home to Work trip purpose originating from this gate",
  },
  {
    column: "O_WH",
    type: "Integer",
    description: "ADT with Work to Home trip purpose originating from this gate",
  },
  {
    column: "O_HO",
    type: "Integer",
    description: "ADT with Home to Other trip purpose originating from this gate",
  },
  {
    column: "O_OH",
    type: "Integer",
    description: "ADT with Other to Home trip purpose originating from this gate",
  },
  {
    column: "O_WO",
    type: "Integer",
    description: "ADT with Work to Other trip purpose originating from this gate",
  },
  {
    column: "O_OW",
    type: "Integer",
    description: "ADT with Other to Work trip purpose originating from this gate",
  },
  {
    column: "O_NHB",
    type: "Integer",
    description: "ADT with Non-home-based trip purpose originating from this gate",
  },
  {
    column: "O_HBW",
    type: "Integer",
    description: "ADT with Home-based-work main purpose originating from this gate (in All-Access license tier only)",
  },
  {
    column: "O_HBO",
    type: "Integer",
    description: "ADT with Home-based-other main purpose originating from this gate",
  },
  {
    column: "O_NHB",
    type: "Integer",
    description:
      "ADT with Non-home-based main purpose originating from this gate incl. “W-O”, “O-W”, “NHB” (in Essentials and Essentials+ license tier only)",
  },
  {
    column: "O_II",
    type: "Integer",
    description: "ADT for trips that start and end inside the subarea (always 0 for gates shapefile)",
  },
  {
    column: "O_IX",
    type: "Integer",
    description: "ADT for trips that start in a zone inside the subarea (always 0 for gates shapefile)",
  },
  {
    column: "O_XI",
    type: "Integer",
    description:
      "ADT for trips that start outside the subarea at this gate and end in another zone inside the subarea.",
  },
  {
    column: "O_XX",
    type: "Integer",
    description: "ADT for trips that start outside the subarea at this gate and end outside the subarea",
  },
  {
    column: "D_TOTAL",
    type: "Integer",
    description: "Total number of Average Daily Traffic (ADT) ending at gate",
  },
  {
    column: "D_DT1",
    type: "Integer",
    description: "ADT on Mondays-Thursdays ending in this gate",
  },
  {
    column: "D_DT2",
    type: "Integer",
    description: "ADT on Fridays ending in this gate",
  },
  {
    column: "D_DT3",
    type: "Integer",
    description: "ADT on Saturdays ending in this gate",
  },
  {
    column: "D_DT4",
    type: "Integer",
    description: "ADT on Sundays ending in this gate",
  },
  {
    column: "D_DP1",
    type: "Integer",
    description: "ADT between midnight and 6am ending in this gate",
  },
  {
    column: "D_DP2",
    type: "Integer",
    description: "ADT between 6am and 10am ending in this gate",
  },
  {
    column: "D_DP3",
    type: "Integer",
    description: "ADT between 10am and 3pm ending in this gate",
  },
  {
    column: "D_DP4",
    type: "Integer",
    description: "ADT between 3pm and 7pm ending in this gate",
  },
  {
    column: "D_DP5",
    type: "Integer",
    description: "ADT between 7pm and midnight ending in this gate",
  },
  {
    column: "D_HW",
    type: "Integer",
    description: "ADT with Home to Work trip purpose ending in this gate",
  },
  {
    column: "D_WH",
    type: "Integer",
    description: "ADT with Work to Home trip purpose ending in this gate",
  },
  {
    column: "D_HO",
    type: "Integer",
    description: "ADT with Home to Other trip purpose ending in this gate",
  },
  {
    column: "D_OH",
    type: "Integer",
    description: "ADT with Other to Home trip purpose ending in this gate",
  },
  {
    column: "D_WO",
    type: "Integer",
    description: "ADT with Work to Other trip purpose ending in this gate",
  },
  {
    column: "D_OW",
    type: "Integer",
    description: "ADT with Other to Work trip purpose ending in this gate",
  },
  {
    column: "D_NHB",
    type: "Integer",
    description: "ADT with Non-home-based trip purpose ending in this gate",
  },
  {
    column: "D_HBW",
    type: "Integer",
    description: "ADT with Home-based-work trip purpose ending in this gate",
  },
  {
    column: "D_HBO",
    type: "Integer",
    description: "ADT with Home-based-other trip purpose ending in this gate",
  },
  {
    column: "D_II",
    type: "Integer",
    description: "ADT for trips that start and end inside the subarea (always 0 for gates shapefile)",
  },
  {
    column: "D_IX",
    type: "Integer",
    description: "ADT for trips that start inside the subarea and end at a gate",
  },
  {
    column: "D_XI",
    type: "Integer",
    description: "ADT for trips that start at a gate and end in this gate (always 0 for gates shapefile)",
  },
  {
    column: "D_XX",
    type: "Integer",
    description: "ADT for trips  that start at a gate and end at gate",
  },
];
