import styled from "@emotion/styled";
import TipsAndUpdates from "@mui/icons-material/TipsAndUpdates";
import { Box, Link, Typography } from "@mui/material";
import { Avatar, Button, CircularProgress, Dialog } from "components_new";
import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { StandardTitle } from "components/atoms/page-container/PageContainerPanel";

import { useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { globalActions } from "store/sections/global";

import { ReleaseNoteBox } from "./ReleaseNoteBox";

export interface ReleaseInfoProps {
  triggerGAEvent: (event: string) => void;
}

export const ReleaseDescription = styled(Typography)`
  font-size: 0.75rem;
  font-weight: 400;
  padding-right: 0.3rem;
`;

const ReleaseTitleContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const ReleaseIconAvatar = styled(Avatar)`
  background-color: #0067b01a;
  color: #0067b0;
`;

const ReleaseInfoContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: center;
`;

const LoaderWrapper = styled(Box)`
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ReleaseInfo: FC<ReleaseInfoProps> = ({ triggerGAEvent }) => {
  const dispatch = useDispatch();

  const latestRelease = useAppSelector((state) => state.global.latestRelease);
  const latestReleaseInfo = latestRelease.data?.data[0];

  const [isReleaseNotesOpen, setIsReleaseNotesOpen] = useState(false);

  useEffect(() => {
    if (latestRelease.state === DataState.EMPTY) {
      dispatch(globalActions.fetchLastRelease());
    }
  }, [latestRelease.state, dispatch]);

  const handleOpenReleaseNotes = () => {
    triggerGAEvent("Release notes");
    setIsReleaseNotesOpen(true);
  };

  const isRecentRelease = (releaseDate: Date) => {
    // If the release date was in the last two weeks we show the release icon
    return new Date(releaseDate) >= new Date(Date.now() - 14 * 24 * 60 * 60 * 1000);
  };

  const onClose = () => {
    setIsReleaseNotesOpen(false);
  };

  return (
    <ReleaseInfoContainer>
      {latestReleaseInfo ? (
        <Box>
          <StandardTitle mt={2.5}>Latest release</StandardTitle>
          <ReleaseTitleContainer>
            <ReleaseIconAvatar variant="rounded">
              <TipsAndUpdates color={isRecentRelease(latestReleaseInfo.releasedAt) ? "primary" : "secondary"} />
            </ReleaseIconAvatar>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ fontWeight: 500, textDecoration: "none" }}>{latestReleaseInfo.name}</Typography>
              <ReleaseDescription color="textSecondary">{latestReleaseInfo.description}</ReleaseDescription>
              <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                <Button
                  size="small"
                  color="secondary"
                  variant="text"
                  sx={{ p: 0, m: 0 }}
                  onClick={handleOpenReleaseNotes}
                >
                  Release notes
                </Button>
                {latestReleaseInfo.announcementUrl ? (
                  <Link
                    href={latestReleaseInfo.announcementUrl}
                    target="_blank"
                    sx={{ display: "flex", textDecoration: "none" }}
                  >
                    <Button
                      size="small"
                      color="secondary"
                      variant="text"
                      sx={{ p: 0, m: 0 }}
                      onClick={() => triggerGAEvent("Release marketing post")}
                    >
                      Blog post
                    </Button>
                  </Link>
                ) : null}
                {/* @TODO only once there is more than one release: link to releases page */}
                {/* <ReleaseLinkButton size="small" color="secondary" variant="text">
                All releases
              </ReleaseLinkButton> */}
              </Box>
            </Box>
          </ReleaseTitleContainer>
        </Box>
      ) : (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      )}
      <Dialog
        open={isReleaseNotesOpen}
        onClose={onClose}
        maxWidth="md"
        closeAfterTransition={false}
        actions={
          <Button size="medium" color="secondary" onClick={onClose}>
            Close
          </Button>
        }
      >
        <Box p={0} mt={1}>
          <ReleaseNoteBox releaseDetails={latestReleaseInfo!} triggerGAEvent={triggerGAEvent} />
        </Box>
      </Dialog>
    </ReleaseInfoContainer>
  );
};
