import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "store";

import {
  DataDetail,
  DataPermission,
  FocusAreaItem,
  IsAllowed,
  LicensedArea,
  SoftwarePermissions,
  UserPermissions,
} from "types";

/**
 * Callback to get a permission from a software license
 * @example
 * (sp) => sp.selectLink.allowSelectLink
 */
type GetPermission = (sp: SoftwarePermissions) => IsAllowed;

/**
 * Callback to get the data detail of a licensed area
 * @example
 * (dd) => dd.corridorDetail
 */
type GetDataPermission = (dd: DataDetail) => DataPermission | undefined;

/**
 * Internal method.
 * Used internally to implement other methods.
 * In the future we may have cases where we may want to relate multiple permissions for a feature, this method is future prof
 */
const filterFocusAreasFilter = (
  focusAreas: FocusAreaItem[] | null,
  licensePermissions: UserPermissions | null,
  licensedAreasMap: Map<string, LicensedArea> | null,
  getPermissionList: GetPermission[],
  getDataPermissionList: GetDataPermission[],
  allowedOnDatasets: boolean,
): FocusAreaItem[] | null => {
  const filterAllowedOnDatasets = allowedOnDatasets ? () => true : (fa: FocusAreaItem) => fa.datasetId === undefined;

  return (
    focusAreas
      // apply the allow dataset filter
      ?.filter(filterAllowedOnDatasets)
      ?.filter((fa) => {
        const licensedAreaPermissions = licensedAreasMap?.get(fa.licensedAreaId ?? fa.id);
        if (licensedAreaPermissions !== undefined) {
          // search allowed permissions
          const allowedPermission = getPermissionList.find(
            (getPermission) =>
              (licensePermissions && getPermission(licensePermissions.softwarePermissions).Allowed) ||
              getPermission(licensedAreaPermissions.softwarePermissions).Allowed,
          );
          // search allowed data permissions
          const allowedDataPermission = getDataPermissionList.find((getDataPermission) =>
            getDataPermission(licensedAreaPermissions.dataDetail),
          );

          // if wasn't found any not allowed permission, user will have access
          return (
            (getPermissionList.length === 0 || allowedPermission !== undefined) &&
            (getDataPermissionList.length === 0 || allowedDataPermission !== undefined)
          );
        } else {
          // if there aren't permission regarding this data license, user shouldn't have access
          return false;
        }
      }) || null
  );
};

const permissionsData = (state: RootState) => state.license.permissions.data;
const focusAreasAndDatasetsData = (state: RootState) => state.analytics.focusAreasAndDatasets.data;

const selectLicensedAreasPermissionsMap = createSelector([permissionsData], (permissions) =>
  permissions ? new Map(permissions.licensedAreas.map((la) => [String(la.licensedAreaId), la])) : null,
);

/**
 * Check if the user has at least one licensed area with that permission
 *
 * @param getPermission - Callback to get a permission from a software license
 *
 * @example
 * // Create an instance of the selector
 * export const selectAtLeastOneSelectLinkPermission = makeSelectAtLeastOnePermission((sp) => sp.selectLink.allowSelectLink);
 * // Use the selector
 * const isSelectLinkAllowed = useAppSelector(selectAtLeastOneSelectLinkPermission)
 */
export const makeSelectAtLeastOnePermission = (getPermission: GetPermission) => {
  const selectAtLeastOnePermission = createSelector([permissionsData], (permissions) =>
    permissions
      ? getPermission(permissions?.softwarePermissions).Allowed !== undefined ||
        permissions.licensedAreas.find((la) => getPermission(la.softwarePermissions).Allowed) !== undefined
      : false,
  );

  return selectAtLeastOnePermission;
};

export const selectAtLeastOneSelectLinkPermission = makeSelectAtLeastOnePermission(
  (sp) => sp.selectLink.allowSelectLink,
);

/**
 * **Check if the user has at least one licensed area with that data permission**
 *
 * @param getDetailedData - Callback to get the data detail of a licensed area.
 *
 * @example
 * // Create an instance of the selector
 * export const selectAtLeastOneCorridorDataPermission = makeSelectAtLeastOneDataPermission((dp) => dp.corridorDetail);
 * // Use the selector
 * const isCorridorAllowed = useAppSelector(selectAtLeastOneCorridorDataPermission);
 */
export const makeSelectAtLeastOneDataPermission = (getDetailedData: GetDataPermission) => {
  const selectAtLeastOneDataPermission = createSelector(
    [permissionsData],
    (permissions) => permissions?.licensedAreas.find((la) => getDetailedData(la.dataDetail)) !== undefined,
  );
  return selectAtLeastOneDataPermission;
};

export const selectAtLeastOneCorridorDataPermission = makeSelectAtLeastOneDataPermission((dp) => dp.corridorDetail);
export const selectAtLeastOneRoadVmtDataPermission = makeSelectAtLeastOneDataPermission((dp) => dp.roadVmtDetail);
export const selectAtLeastOneScreenlinePermission = makeSelectAtLeastOnePermission(
  (p) => p.screenlines.allowUseScreenlines,
);

/**
 * **Filter the list of focusAreas which allows a certain software Permission**
 *
 * @param getPermission - Callback to get a permission from a software license.
 * @param allowedOnDatasets - If true, will return also datasets, if false, will return only licensed areas.
 *
 * @example
 * // Create an instance of the selector
 * export const selectFocusAreasByAllowSelectLink = makeSelectFocusAreasByPermission((sp) => sp.selectLink.allowSelectLink, false);
 * // Use the selector
 * const aoiAreas = useAppSelector(selectFocusAreasByAllowSelectLink)
 */
export const makeSelectFocusAreasByPermission = (getPermission: GetPermission, allowedOnDatasets: boolean = true) => {
  const selectFocusAreasByPermission = createSelector(
    [permissionsData, focusAreasAndDatasetsData, selectLicensedAreasPermissionsMap],
    (permissions, focusAreas, licensedAreasPermissionsMap) =>
      filterFocusAreasFilter(
        focusAreas,
        permissions,
        licensedAreasPermissionsMap,
        [getPermission],
        [],
        allowedOnDatasets,
      ),
  );

  return selectFocusAreasByPermission;
};

/**
 * **Filter the list of focusAreas which allows a certain software Permission**
 *
 * @param getDataPermission - Callback to get the data detail of a licensed area.
 * @param allowedOnDatasets - If true, will return also datasets, if false, will return only licensed areas.
 *
 * @example
 * // Create an instance of the selector
 * export const selectFocusAreasByCorridorData = makeSelectFocusAreasByDataPermission((dd) => dd.corridorDetail, false);
 * // Use the selector
 * const aoiAreas = useAppSelector(selectFocusAreasByCorridorData)
 */
export const makeSelectFocusAreasByDataPermission = (
  getDataPermission: GetDataPermission,
  allowedOnDatasets: boolean = true,
) => {
  const selectFocusAreasByDataPermission = createSelector(
    [focusAreasAndDatasetsData, permissionsData, selectLicensedAreasPermissionsMap],
    (focusAreas, permissions, licensedAreasPermissionsMap) =>
      filterFocusAreasFilter(
        focusAreas,
        permissions,
        licensedAreasPermissionsMap,
        [],
        [getDataPermission],
        allowedOnDatasets,
      ),
  );

  return selectFocusAreasByDataPermission;
};

const selectLicensedAreasByAllowSelectLinkPermission = makeSelectFocusAreasByPermission(
  (sp) => sp.selectLink.allowSelectLink,
  false,
);

const selectLicensedAreasByAllowODDetail = makeSelectFocusAreasByDataPermission((sp) => sp.odDetail, false);

export const selectLicensedAreasByAllowSelectLink = createSelector(
  [selectLicensedAreasByAllowSelectLinkPermission, selectLicensedAreasByAllowODDetail],
  (areasWithSelectLinkPremissions, areasWithODDetails) => {
    const areasWithODDetailsIds = new Set(areasWithODDetails?.map((area) => area.id));

    return areasWithSelectLinkPremissions?.filter((area) => areasWithODDetailsIds.has(area.id)) || [];
  },
);

export const selectLicensedAreasByAllowVmt = makeSelectFocusAreasByDataPermission((sp) => sp.roadVmtDetail, false);

export const selectLicensedAreasByAllowCorridor = makeSelectFocusAreasByDataPermission(
  (sp) => sp.corridorDetail,
  false,
);

export const selectHasODDetail = createSelector(
  [selectLicensedAreasByAllowODDetail, (state, licensedAreaId) => licensedAreaId],
  (licensedAreas, licensedAreaId) =>
    licensedAreaId &&
    licensedAreas &&
    licensedAreas.some((licensedArea) => licensedArea.licensedAreaId === licensedAreaId),
);

export const selectAreScreenlinesAllowed = createSelector(
  [selectLicensedAreasPermissionsMap, (state, licensedAreaId) => licensedAreaId],
  (permissionsMap, licensedAreaId) => {
    const licensedAreaPermisions = permissionsMap?.get(String(licensedAreaId));
    return licensedAreaPermisions?.softwarePermissions?.screenlines?.allowUseScreenlines?.Allowed ?? false;
  },
);

export const selectAreRoadIntersectionsAllowed = createSelector(
  [selectLicensedAreasPermissionsMap, (state, licensedAreaId) => licensedAreaId],
  (permissionsMap, licensedAreaId) => {
    const licensedAreaPermisions = permissionsMap?.get(String(licensedAreaId));
    return Boolean(
      licensedAreaPermisions?.softwarePermissions?.roadIntersections?.allowRoadIntersections?.Allowed ?? false,
    );
  },
);
