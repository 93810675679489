import { Map as MapboxMap } from "mapbox-gl";
import { Dispatch, MutableRefObject, SetStateAction } from "react";

import { RoadsHoverPopupProps, VolumePopupContentProps } from "components";

import {
  ExtendedDirectionalRoadsTileService,
  ExtendedNonDirectionalRoadsTileService,
  MeasureType,
  SelectedVolume,
  Volume,
} from "types";

import { initUserInteractionsWithMap } from "./userInteractions";
import { initVolumesSources } from "./volumes";

export interface RoadLayerIds {
  roadsSourceId: string;
  roadsVolumesLayerId: string;
  limitedAccessRoadsVolumesLayerId: string;
  roadsHairlinesLayerId: string;
  roadsHighlightedVolumesLayerId: string;
  roadsSegmentsLayerId: string;
  highlightedSegmentsLayerId: string;
  roadsMeasureRangeLayerId: string;
}

export const getRoadHandlers = (
  map: MapboxMap,
  measureRef: React.RefObject<MeasureType>,
  tileService: ExtendedDirectionalRoadsTileService | ExtendedNonDirectionalRoadsTileService,
  volumesSize: number,
  maxVolumeRef: React.RefObject<number>,
  popupRoadSegmentHoverRef: any,
  popupRoadVolumesRef: any,
  mapboxVolumesPopupRef: any,
  setRoadHoverProps: React.RefObject<Dispatch<SetStateAction<RoadsHoverPopupProps | null>>>,
  blockClickEventRef: MutableRefObject<boolean | undefined> | undefined,
  suppressRoadVolumePopupAndStatesRef: MutableRefObject<boolean>,
  setVolumeProps: Dispatch<SetStateAction<Volume[]>>,
  setRoadsVolumesRef: React.RefObject<Dispatch<SetStateAction<VolumePopupContentProps | null>>>,
  updateRoadsPopupCounts: React.MutableRefObject<
    ((selectedVolume: SelectedVolume | null, volumesProps?: any[]) => void) | null
  >,
  setVolumesLoading: Dispatch<SetStateAction<boolean>>,
  widthFactorRef: MutableRefObject<number>,
  setSelectedRoadVolume?: (selectedRoadVolume: SelectedVolume | null) => void,
  setSelectedRoadVolumeId?: (selectedRoadVolumeId: string) => void,
  closeRoadIntersectionsAnalyticsPanelRef?: MutableRefObject<(() => void) | null>,

  // select link specific args
  setSelectedLink?: React.MutableRefObject<(selectedRoadVolume: SelectedVolume, selectedRoadVolumeId: string) => void>,
  isSelectLink?: boolean,
  isSelectLinkResults?: boolean,
) => {
  const { cleanUserInteractionsHandlers } = initUserInteractionsWithMap(
    map,
    measureRef,
    tileService,
    popupRoadSegmentHoverRef,
    popupRoadVolumesRef,
    mapboxVolumesPopupRef,
    setRoadHoverProps,
    blockClickEventRef,
    suppressRoadVolumePopupAndStatesRef,
    setVolumeProps,
    setRoadsVolumesRef,
    updateRoadsPopupCounts,
    setSelectedRoadVolume,
    setSelectedRoadVolumeId,
    closeRoadIntersectionsAnalyticsPanelRef,
    setSelectedLink,
    isSelectLink,
    isSelectLinkResults,
  );

  const { cleanVolumesHandlers, updateRoadsVolumes } = initVolumesSources(
    map,
    measureRef,
    tileService,
    volumesSize,
    maxVolumeRef,
    setVolumesLoading,
    widthFactorRef,
    isSelectLinkResults,
  );

  return {
    cleanRoadsHandlers: () => {
      cleanUserInteractionsHandlers();
      cleanVolumesHandlers();
    },
    updateRoadsVolumes,
  };
};
