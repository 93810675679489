import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import memoryStore, { MemoryStoreKeys } from "api/memoryStore";
import { Popup } from "mapbox-gl";
import { Dispatch, MutableRefObject, RefObject, SetStateAction, useEffect, useMemo, useRef, useState } from "react";

import { ModuleData } from "features/map/ModuleManager";

import { useAppDispatch, useAppSelector, useStateRef } from "hooks";

import { DataState } from "store/interfaces";
import { screenlinesActions } from "store/sections/screenlines";

import { MeasureType } from "types";

import {
  useChangeRoadIntersectionsOpacity,
  useChangeShowRoadIntersections,
  useClearRoadIntersectionVolumeDetails,
  useClearSelectedIntersections,
  useCloseRoadIntersectionsAnalyticsPanel,
  useFetchRoadIntersectionClusterIds,
  useFetchRoadIntersectionClusterVolumes,
  useFetchRoadIntersectionIds,
  useFetchRoadIntersectionVolumeDetails,
  useFetchRoadIntersectionVolumes,
  useSetIsBaseIntersectionLevel,
  useSetIsDrawModeActive,
  useSetSelectedIntersectionId,
  useSetSelectedIntersections,
  useUpdateFeatureStateForRoadIntersections,
} from "./ControllerCallbacks";
import { getRoadIntersectionClusterLayers, getRoadIntersectionNodeLayers } from "./map-data/layers";
import { getRoadIntersectionClusterSources, getRoadIntersectionNodeSources } from "./map-data/sources";

interface Props {
  map: MutableRefObject<mapboxgl.Map | null>;
  measure: MeasureType;
  layerManagerRef: MutableRefObject<LayerManager | null>;
  setRoadIntersectionsModuleData: Dispatch<SetStateAction<ModuleData | null>>;
  updateVolumesRef: MutableRefObject<(() => void) | null>;
  closeRoadIntersectionsAnalyticsPanelRef: RefObject<() => void>;
  clearSelectedIntersectionsRef: RefObject<() => void>;
}

export const MapController = ({
  map,
  measure,
  layerManagerRef,
  setRoadIntersectionsModuleData,
  updateVolumesRef,
  closeRoadIntersectionsAnalyticsPanelRef,
  clearSelectedIntersectionsRef,
}: Props) => {
  const dispatch = useAppDispatch();

  const [baseLayerName, setBaseLayerName] = useState<string>("");

  const mapboxRoadIntersectionHoverPopupRef = useRef<Popup | null>(null);

  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);

  const timePeriod = useAppSelector((state) => state.global.timePeriod);

  const roadsMetadata = useAppSelector((state) => state.analytics.roadsMetadata);
  const roadFilters = useAppSelector((state) => state.filters.roadFilters);

  const baseLevel = useMemo(
    () => roadsMetadata.data?.intersectionLevels?.[measure]?.baseLevel,
    [roadsMetadata.data, measure],
  );

  const clusterLevels = useMemo(
    () => roadsMetadata.data?.intersectionLevels?.[measure]?.clusterLevels,
    [roadsMetadata.data, measure],
  );

  const allLevels = useMemo(
    () => [...(baseLevel ? [baseLevel] : []), ...(clusterLevels || [])],
    [baseLevel, clusterLevels],
  );

  //Screenlines
  const selectedScreenlineId = useAppSelector((state) => state.screenlines.selectedScreenlineId);
  const isScreelineEditorOpen = useAppSelector((state) => state.screenlines.isScreelineEditorOpen);

  const showRoadIntersections = useAppSelector((state) => state.roadIntersections.showRoadIntersections);
  const showRoadIntersectionsRef = useStateRef(showRoadIntersections);
  const changeShowRoadIntersections = useChangeShowRoadIntersections(layerManagerRef, allLevels);

  const changeRoadIntersectionsOpacity = useChangeRoadIntersectionsOpacity(map, allLevels, layerManagerRef);

  const roadIntersectionVolumes = useAppSelector((state) => state.roadIntersections.intersectionVolumes);
  const roadIntersectionIds = useAppSelector((state) => state.roadIntersections.intersectionIds);
  const selectedIntersectionId = useAppSelector((state) => state.roadIntersections.selectedIntersectionId);
  const selectedIntersectionIdRef = useStateRef(selectedIntersectionId);
  const selectedIntersections = useAppSelector((state) => state.roadIntersections.selectedIntersections);
  const selectedIntersectionsRef = useStateRef(selectedIntersections);
  const roadIntersectionVolumeDetails = useAppSelector((state) => state.roadIntersections.intersectionVolumeDetails);

  const roadIntersectionClusterVolumes = useAppSelector((state) => state.roadIntersections.intersectionClusterVolumes);
  const roadIntersectionClusterIds = useAppSelector((state) => state.roadIntersections.intersectionClusterIds);

  const aggregationFunction = useAppSelector((state) => state.roadIntersections.aggregationFunction);
  const isDrawModeActive = useAppSelector((state) => state.roadIntersections.isDrawModeActive);
  const isDrawModeActiveRef = useStateRef(isDrawModeActive);

  // TODO: delay fetching volumes and ids to when pedestrian measure is actually selected
  const fetchRoadIntersectionVolumes = useFetchRoadIntersectionVolumes(timePeriod, measure, roadFilters);
  const fetchRoadIntersectionIds = useFetchRoadIntersectionIds(timePeriod, selectedFocusArea?.datasetId);
  const fetchRoadIntersectionVolumeDetails = useFetchRoadIntersectionVolumeDetails(
    roadsMetadata.data,
    roadFilters,
    timePeriod,
  );
  const clearRoadIntersectionVolumeDetails = useClearRoadIntersectionVolumeDetails();
  const setSelectedRoadIntersectionId = useSetSelectedIntersectionId();
  const fetchRoadIntersectionClusterIds = useFetchRoadIntersectionClusterIds(timePeriod, selectedFocusArea?.datasetId);
  const fetchRoadIntersectionClusterVolumes = useFetchRoadIntersectionClusterVolumes(timePeriod, measure, roadFilters);

  const updateFeatureStateForRoadIntersections = useUpdateFeatureStateForRoadIntersections(map, baseLayerName);

  const setIsDrawModeActive = useSetIsDrawModeActive();
  const setIsBaseIntersectionLevel = useSetIsBaseIntersectionLevel();
  const setSelectedIntersections = useSetSelectedIntersections();
  const clearSelectedIntersections = useClearSelectedIntersections(
    map,
    selectedIntersections,
    setSelectedIntersections,
    setIsDrawModeActive,
    updateFeatureStateForRoadIntersections,
  );

  const closeRoadIntersectionsAnalyticsPanel = useCloseRoadIntersectionsAnalyticsPanel(
    map,
    selectedIntersectionId,
    setSelectedRoadIntersectionId,
    updateFeatureStateForRoadIntersections,
    clearSelectedIntersections,
  );

  // Close roads analytics panel on measure change
  useEffect(() => {
    if (measure) {
      closeRoadIntersectionsAnalyticsPanelRef.current?.();
    }
  }, [measure, closeRoadIntersectionsAnalyticsPanelRef]);

  useEffect(() => {
    if (roadsMetadata.state === DataState.AVAILABLE && roadIntersectionIds.state === DataState.EMPTY) {
      fetchRoadIntersectionIds();
    }
  }, [roadsMetadata.state, roadIntersectionIds.state, fetchRoadIntersectionIds]);

  useEffect(() => {
    if (roadsMetadata.state === DataState.AVAILABLE) {
      if (roadIntersectionVolumes.state === DataState.EMPTY) {
        fetchRoadIntersectionVolumes();
      }

      if (roadIntersectionVolumes.state === DataState.AVAILABLE) {
        updateVolumesRef.current?.();
      }
    }
  }, [roadsMetadata.state, roadIntersectionVolumes.state, fetchRoadIntersectionVolumes, updateVolumesRef]);

  useEffect(() => {
    if (roadsMetadata.state === DataState.AVAILABLE && roadIntersectionClusterIds.state === DataState.EMPTY) {
      fetchRoadIntersectionClusterIds();
    }
  }, [roadsMetadata.state, roadIntersectionClusterIds.state, fetchRoadIntersectionClusterIds]);

  useEffect(() => {
    if (roadsMetadata.state === DataState.AVAILABLE) {
      if (roadIntersectionClusterVolumes.state === DataState.EMPTY) {
        fetchRoadIntersectionClusterVolumes(aggregationFunction);
      }

      if (roadIntersectionClusterVolumes.state === DataState.AVAILABLE) {
        updateVolumesRef.current?.();
      }
    }
  }, [
    roadsMetadata.state,
    roadIntersectionClusterVolumes.state,
    fetchRoadIntersectionClusterVolumes,
    aggregationFunction,
    updateVolumesRef,
  ]);

  // Fetch intersection volume details for selected node or multiple nodes
  useEffect(() => {
    if (roadsMetadata.state === DataState.AVAILABLE && roadIntersectionVolumeDetails.state === DataState.EMPTY) {
      if (selectedIntersectionId) {
        fetchRoadIntersectionVolumeDetails([Number(selectedIntersectionId)], measure, aggregationFunction);
      } else if (selectedIntersections && selectedIntersections.length > 0) {
        fetchRoadIntersectionVolumeDetails(
          selectedIntersections.map((id) => Number(id)),
          measure,
          aggregationFunction,
        );
      }
    }
  }, [
    roadsMetadata.state,
    roadIntersectionVolumes.state,
    roadIntersectionVolumeDetails.state,
    measure,
    selectedIntersectionId,
    selectedIntersections,
    aggregationFunction,
    fetchRoadIntersectionVolumeDetails,
  ]);

  // Clear details state when intersection is selected or unselected
  useEffect(() => {
    clearRoadIntersectionVolumeDetails();
  }, [selectedIntersectionId, selectedIntersections, clearRoadIntersectionVolumeDetails]);

  // Update close analytics panel ref function
  useEffect(() => {
    (closeRoadIntersectionsAnalyticsPanelRef as MutableRefObject<() => void>).current =
      closeRoadIntersectionsAnalyticsPanel;
  }, [closeRoadIntersectionsAnalyticsPanelRef, closeRoadIntersectionsAnalyticsPanel]);

  // Update clear intersections ref function
  useEffect(() => {
    (clearSelectedIntersectionsRef as MutableRefObject<() => void>).current = clearSelectedIntersections;
  }, [clearSelectedIntersectionsRef, clearSelectedIntersections]);

  useEffect(() => {
    if (selectedScreenlineId || isScreelineEditorOpen) {
      closeRoadIntersectionsAnalyticsPanelRef.current?.();
      dispatch(screenlinesActions.setDrawMode(false));
    }
  }, [dispatch, selectedScreenlineId, isScreelineEditorOpen, closeRoadIntersectionsAnalyticsPanelRef]);

  // Set module data once, source will be turned on/off based on the selected measure
  useEffect(() => {
    if (
      selectedFocusArea &&
      roadsMetadata.state === DataState.AVAILABLE &&
      roadIntersectionVolumes.data &&
      roadIntersectionIds.data &&
      roadIntersectionClusterVolumes.data &&
      roadIntersectionClusterIds.data
    ) {
      // TODO: make filtering by ids dynamic, update layer filter in a separate useEffect
      const ids = memoryStore.getItem(MemoryStoreKeys.ROAD_INTERSECTION_IDS) as number[];

      const clusterIds = memoryStore.getItem(MemoryStoreKeys.ROAD_INTERSECTION_CLUSTER_IDS) as Map<
        number,
        Map<number, number>
      >;

      const areClustersAvailable = clusterIds && clusterIds.size > 0;
      const clusterSources = areClustersAvailable ? getRoadIntersectionClusterSources(roadsMetadata.data, measure) : [];

      const sources = [...getRoadIntersectionNodeSources(roadsMetadata.data, measure), ...clusterSources];

      const baseLayers = getRoadIntersectionNodeLayers(
        showRoadIntersectionsRef,
        baseLevel?.minZoomLevel || 14,
        baseLevel?.maxZoomLevel || 22,
        baseLevel,
        ids,
      );

      const clusterLayers = areClustersAvailable
        ? getRoadIntersectionClusterLayers(showRoadIntersectionsRef, clusterLevels, clusterIds)
        : [];

      const layers = [...baseLayers, ...clusterLayers];

      setRoadIntersectionsModuleData((data) => {
        if (data && data.data.tileService.url === roadsMetadata.data!.pedestrianRoadsTileService?.url) {
          return data;
        } else {
          closeRoadIntersectionsAnalyticsPanelRef.current?.();

          return {
            sources,
            layers,
            data: {
              tileService: roadsMetadata.data!.pedestrianRoadsTileService,
              changeShowRoadIntersections,
              changeRoadIntersectionsOpacity,
              levels: allLevels,
              volumesDetails: roadIntersectionVolumes.data,
              clusterVolumesDetails: roadIntersectionClusterVolumes.data,
              mapboxRoadIntersectionHoverPopupRef,
              selectedIntersectionIdRef,
              selectedIntersectionsRef,
              closeRoadIntersectionsAnalyticsPanelRef,
              clearSelectedIntersectionsRef,
              isDrawModeActiveRef,
              setIsDrawModeActive,
              setIsBaseIntersectionLevel,
              setSelectedRoadIntersectionId,
              setSelectedIntersections,
            },
          };
        }
      });

      if (baseLevel?.tileService.layerName) {
        setBaseLayerName(baseLevel.tileService.layerName);
      }
    }
  }, [
    selectedFocusArea,
    measure,
    showRoadIntersectionsRef,
    roadsMetadata.state,
    roadsMetadata.data,
    roadIntersectionVolumes.data,
    roadIntersectionIds.data,
    roadIntersectionClusterVolumes.data,
    roadIntersectionClusterIds.data,
    baseLevel,
    clusterLevels,
    allLevels,
    changeShowRoadIntersections,
    changeRoadIntersectionsOpacity,
    setRoadIntersectionsModuleData,
    setSelectedRoadIntersectionId,
    closeRoadIntersectionsAnalyticsPanelRef,
    clearSelectedIntersectionsRef,
    selectedIntersectionIdRef,
    selectedIntersectionsRef,
    isDrawModeActiveRef,
    setIsDrawModeActive,
    setIsBaseIntersectionLevel,
    setSelectedIntersections,
  ]);

  return null;
};
