import { Map as MapboxMap } from "mapbox-gl";

import {
  getRoadIntersectionLayerNameForLevel,
  getRoadIntersectionsLayerCircleColorExpression,
  getRoadIntersectionsLayerCircleRadiusExpression,
} from "features/map/modules/road-intersections/map-data/layers";

import { RoadIntersectionLevel } from "types";

export const updateCircleRadiusExpression = (
  map: MapboxMap,
  level: RoadIntersectionLevel,
  layerId: string,
  maxVolume: number,
  minZoomLevel: number,
  maxZoomLevel: number,
  widthFactor: number,
) => {
  if (map.getLayer(layerId) === undefined) {
    return;
  }

  map.setPaintProperty(
    layerId,
    "circle-radius",
    getRoadIntersectionsLayerCircleRadiusExpression(level, maxVolume, minZoomLevel, maxZoomLevel, widthFactor),
  );
};

export const updateCircleColorExpression = (
  map: MapboxMap,
  layerId: string,
  level: RoadIntersectionLevel,
  maxVolume: number,
) => {
  if (map.getLayer(layerId) === undefined) {
    return;
  }

  map.setPaintProperty(layerId, "circle-color", getRoadIntersectionsLayerCircleColorExpression(level, maxVolume));
};

export const calculateWidthFactor = (value: number) => value ** 1;

export const changeRoadIntersectionsLayersPaintExpressions = (
  map: MapboxMap,
  levels: RoadIntersectionLevel[],
  widthFactor: number,
  maxVolumes: Record<number, number> | null,
) => {
  levels.forEach((level) => {
    const maxRadius = level.maxRadius || 0;
    if (maxVolumes?.[maxRadius]) {
      // cluster level volumes will be absent for focus areas where clustering is not supported
      updateCircleRadiusExpression(
        map,
        level,
        getRoadIntersectionLayerNameForLevel(level),
        maxVolumes[maxRadius],
        level.minZoomLevel,
        level.maxZoomLevel,
        widthFactor,
      );

      updateCircleColorExpression(map, getRoadIntersectionLayerNameForLevel(level), level, maxVolumes[maxRadius]);
    }
  });
};
