import { Box, Typography, styled } from "@mui/material";

const ReleaseNoteTitle = styled(Typography)`
  font-size: 0.9rem !important;
  font-weight: 600;
`;

export const ReleaseNote = ({ title, notes }: { title: string; notes: string[] | undefined }) => {
  return notes && notes.length > 0 ? (
    <Box mb={1}>
      <ReleaseNoteTitle variant="h6">{title}</ReleaseNoteTitle>
      <Box component="ul" sx={{ pl: 2 }}>
        {notes.map((feature, index) => (
          <Typography component="li" key={index} variant="body2">
            {feature}
          </Typography>
        ))}
      </Box>
    </Box>
  ) : null;
};
